import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import Button from "../Button/Button";
import Columns from "./Columns";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "columns"
    }}>{`Columns`}</h1>
    <Props of={Columns} mdxType="Props" />
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`As its name implies, Columns allows you to organize your content and components using columns. You only need to wrap your content inside the `}<inlineCode parentName="p">{`Columns`}</inlineCode>{` tag and specify how many columns do you need in each device-size (desktop, tablet, and mobile). That's it.`}</p>
    <Playground __position={1} __code={'<Columns desktop=\"4\" tablet=\"3\" mobile=\"2\">\n  <Button className=\"btn-primary\">First button</Button>\n  <Button className=\"btn-secondary\">Second Button</Button>\n  <Button className=\"btn-anchor\">Third Button</Button>\n  <Button className=\"btn-primary btn-inactive\">Fourth Button</Button>\n</Columns>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Columns,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Columns desktop='4' tablet='3' mobile='2' mdxType="Columns">
    <Button className="btn-primary" mdxType="Button">First button</Button>
    <Button className="btn-secondary" mdxType="Button">Second Button</Button>
    <Button className="btn-anchor" mdxType="Button">Third Button</Button>
    <Button className="btn-primary btn-inactive" mdxType="Button">Fourth Button</Button>
  </Columns>
    </Playground>
    <h2 {...{
      "id": "image-alignment"
    }}>{`Image alignment`}</h2>
    <p>{`This is an example of how you could align an image to right using `}<inlineCode parentName="p">{`Columns`}</inlineCode>{`.`}</p>
    <Playground __position={2} __code={'<Columns desktop=\"2\" tablet=\"1\" mobile=\"1\">\n  <div>\n    <h3>MoneyGeek is here to help</h3>\n    <p>\n      Want to be a money geek? We provide the tools and information that will\n      help you make sound financial decisions.\n    </p>\n    <p>\n      Our money geeks talk to experts to ensure you get the best financial\n      education so that you can be a money geek.\n    </p>\n  </div>\n  <img\n    style={{ objectFit: \'contain\' }}\n    src=\"https://res.cloudinary.com/moneygeek/image/upload/v1578052030/MoneyGeek.com/media/house_idswdv.jpg\"\n  />\n</Columns>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Button,
      Columns,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Columns desktop="2" tablet="1" mobile="1" mdxType="Columns">
    <div>
    <h3>MoneyGeek is here to help</h3>
    <p>Want to be a money geek? We provide the tools 
    and information that will help you make sound financial decisions.</p>
    <p>Our money geeks talk to experts to ensure you get the best financial education 
    so that you can be a money geek.</p>
    </div>
    <img style={{
          objectFit: 'contain'
        }} src='https://res.cloudinary.com/moneygeek/image/upload/v1578052030/MoneyGeek.com/media/house_idswdv.jpg' />
  </Columns>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      